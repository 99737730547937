define("builder/services/store", ["exports", "@ember-data/store", "builder/core/enumerators/template-visibility", "builder/models/menu-child", "ember-ajax/errors", "ember-copy", "builder/core/copy-menu-management-config", "builder/config/environment", "builder/core/enumerators/cookie"], function (_exports, _store, _templateVisibility, _menuChild, _errors, _emberCopy, _copyMenuManagementConfig, _environment, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class StoreService
   */
  var _default = _store.default.extend({
    /**
     * @property {Ember.Service} ajax - ajax service
     */
    ajax: Ember.inject.service(),

    /**
     * @property {ModulesStoreService} modules - modules store
     */
    companyAndWebsite: Ember.inject.service('company-and-website'),

    /**
     * @property {ConfigurationService} configuration - page service
     */
    configuration: Ember.inject.service(),

    /**
     * @property {String} urlToMenuItem - url for create menu item
     */
    urlToMenuItem: `${_environment.default.apiURL}/menu_item`,

    /**
     * @property {String} urlToModulesClear - url for cleanup modules
     */
    urlToModulesClear: `${_environment.default.apiURL}/page_modulepreview`,

    /**
     * @property {String} urlToMarketSegments - url for get market segment id
     */
    urlToMarketSegments: `${_environment.default.apiURL}/marketsegment`,

    /**
     * @property {AccessControlService} accessControl - access control service
     */
    accessControl: Ember.inject.service('can'),

    /**
     * Create new page with required page meta data(meta)
     * We should reload page meta and seo because backend clones it with the page
     *
     * @param {Object} presets - page parameters to be used as default values upon page creation
     * @returns {Promise} - promise that resolves with object with keys: (page and pageMeta) that contains
     * page and page meta models
     */
    createPage(presets = {}) {
      // TODO for Murod: best way is implement filter for page-meta based on PageId instead load all models
      presets.Type = this._getPageTypeForCopiedPage(presets.Type);
      return this.createRecord('page', presets).save();
    },

    /**
     * Kinda "create" menu item, but basically we create menu item on frontend, all we need from backend is id
     * so here, we make request to get id for menu item, and set to passed in item.
     *
     * @param {Object} item - menu item structure
     * @returns {Promise}
     */
    createMenuItem({
      title,
      type,
      link,
      pageId
    }) {
      const item = {
        itemId: null,
        parentId: 0,
        title,
        type,
        link: this.createFragment('menu-child-link', link),
        config: Ember.Object.extend(_emberCopy.Copyable, {
          copy() {
            return (0, _copyMenuManagementConfig._copy)(this);
          }

        }).create({
          pageId,
          parent: null
        })
      };

      if (type === _menuChild.default.FOLDER) {
        item.children = [];
      }

      return this.ajax.request(this.urlToMenuItem, {
        method: 'POST'
      }).then(response => {
        item.itemId = parseInt(response.Id, 10);
        return item;
      });
    },

    /**
     * Create new market segment model
     *
     * @method createMarketSegment
     * @param {Object} attributes - attributes for model (see model MarketSegment)
     * @returns {Promise.<DS.Model>}
     */
    createMarketSegment(attributes) {
      return this._getMarketSegmentId().then(id => {
        const record = this.push({
          data: {
            id,
            type: 'market-segment',
            attributes
          }
        }); // Mark the model as not saved in backend

        record.transitionTo('created.uncommitted');
        return record;
      });
    },

    /**
     * Send request to backend, to remove all unsaved module configurations
     *
     * @param {Number} pageId - id of page, module configs on that page will be removed from page
     * @returns {Promise}
     */
    clearModules(pageId) {
      const query = [`cleanup=${pageId}`, this.companyAndWebsite.getCompanyWebsiteParams(), `${_cookie.default.SESSION_ID}=${this.get('configuration.sessionId')}`].join('&');
      return this.ajax.request(`${this.urlToModulesClear}/?${query}`, {
        method: 'GET'
      }).catch(function (error) {
        if ((0, _errors.isAjaxError)(error)) {// Handle ajax error, syntax error is kinda fine, since server won't send response on module cleanup
        }
      });
    },

    /**
     * Get template visibilities depending on user acls
     *
     * @returns {Object[]} - list of available visibilities
     */
    getVisibilities() {
      const visibilities = [{
        id: _templateVisibility.default.VISIBILITY_PRIVATE,
        title: this.get('literals.VISIBLE_ONLY_FOR_ME')
      }, {
        id: _templateVisibility.default.VISIBILITY_COMPANY,
        title: this.get('literals.VISIBLE_FOR_EVERYONE_IN_MY_COMPANY')
      }, {
        id: _templateVisibility.default.VISIBILITY_DEALERFIRE_EMPLOYEES,
        title: this.get('literals.VISIBLE_FOR_DEALERFIRE_EMPLOYEES')
      }];

      if (this.accessControl.can('setGlobalVisibility page-template-categories').isGranted) {
        visibilities.push({
          id: _templateVisibility.default.VISIBILITY_GLOBAL,
          title: this.get('literals.VISIBLE_GLOBALLY_FOR_ALL_USERS')
        });
      }

      return visibilities;
    },

    /**
     * Get container template visibilities depending on user acls
     *
     * @returns {Object[]} - list of available visibilities
     */
    getContainerTemplateVisibilities() {
      const visibilities = [{
        id: _templateVisibility.default.VISIBILITY_PRIVATE,
        title: this.get('literals.VISIBLE_ONLY_FOR_ME')
      }, {
        id: _templateVisibility.default.VISIBILITY_COMPANY,
        title: this.get('literals.VISIBLE_FOR_EVERYONE_IN_MY_COMPANY')
      }, {
        id: _templateVisibility.default.VISIBILITY_DEALERFIRE_EMPLOYEES,
        title: this.get('literals.VISIBLE_FOR_DEALERFIRE_EMPLOYEES')
      }];

      if (this.accessControl.can('setGlobalVisibility container-template-categories').isGranted) {
        visibilities.push({
          id: _templateVisibility.default.VISIBILITY_GLOBAL,
          title: this.get('literals.VISIBLE_GLOBALLY_FOR_ALL_USERS')
        });
      }

      return visibilities;
    },

    /**
     * @inheritdoc
     */
    createRecord(modelName, inputProperties, ...args) {
      if (modelName === 'module-style') {
        return this._createModuleStyleRecord(this._super(modelName, inputProperties));
      }

      return this._super(modelName, inputProperties, ...args);
    },

    /**
     * Clone container structure
     * @param {String} templateId - container template id
     * @param {String} pageId - page id where container will be added
     * @param {String} sessionId - session id
     * @returns {Promise}
     */
    cloneContainerTemplateStructure(templateId, pageId, sessionId) {
      return this.ajax.request(`${_environment.default.apiURL}/containertemplate_clone?${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.get('configuration.sessionId')}`, {
        method: 'POST',
        data: {
          containerTemplateClone: {
            Id: templateId,
            PageId: pageId,
            SessionId: sessionId
          }
        }
      }).then(response => {
        const structure = Ember.get(response, 'containerTemplateClone.Structure.firstObject');

        if (!structure) {
          return Ember.RSVP.reject(new Ember.Error('Cloned container template structure is not provided'));
        }

        return structure;
      });
    },

    /**
     * Finds module config in preview table
     * @param {Number} id - Module config id
     * @returns {Promise}
     */
    findModuleConfigPreview(id) {
      return this.ajax.request(`${_environment.default.apiURL}/page_modulepreview/${id}?${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.get('configuration.sessionId')}`);
    },

    /**
     * Gets page structure 
     * @param {Number} id - page id
     * @returns {Promise}
     */
    async getPageStructure(id) {
      // Call API to get the updated BaseStructure
      let updatedPage = await this.ajax.request(`${_environment.default.apiURL}/page/${id}?${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.get('configuration.sessionId')}`);
      return updatedPage.page;
    },

    /**
     * Gets page meta 
     * @param {Number} id - metaid
     * @returns {Promise}
     */
    async getPageMeta(id) {
      // Call API to get the updated page meta
      let res = await this.ajax.request(`${_environment.default.apiURL}/page_meta/${id}?${this.companyAndWebsite.getCompanyWebsiteParams()}&${_cookie.default.SESSION_ID}=${this.get('configuration.sessionId')}`);
      return res.pageMeta;
    },

    /**
     * Method return type for copied page
     *
     * @method _getPageTypeForCopiedPage
     * @param {String} type - the type of page to copy from
     * @return {String} type - the type for copied page
     * @private
     */
    _getPageTypeForCopiedPage(type) {
      const pageTypesProvider = Ember.getOwner(this).lookup('provider:page-types');
      const pageType = pageTypesProvider.findByValue(type);
      let effectivePageType = pageType ? pageType.value : pageTypesProvider.get('defaultType.value');

      if (pageType && pageType.isUniq) {
        const pageModel = this.peekAll('page').findBy('Type', pageType.value);

        if (pageModel && !pageModel.get('isDeleted')) {
          effectivePageType = pageTypesProvider.get('defaultType.value');
        }
      }

      return effectivePageType;
    },

    /**
     * Get id for market segment
     *
     * @method _getMarketSegmentId
     * @return {Promise.<string>}
     * @private
     */
    _getMarketSegmentId() {
      return this.ajax.request(this.urlToMarketSegments, {
        method: 'POST'
      }).then(response => {
        if (!Ember.get(response, 'marketsegment.Id')) {
          throw new Ember.Error('Id for market segment did not received.');
        }

        return Ember.get(response, 'marketsegment.Id').toString();
      });
    },

    /**
     * Creates recorded for module styles
     *
     * @method _createModuleStyleRecord
     * @param {ModuleStyleModel} tmpRecord - temporary record to which will be used to configure and
     * @return {ModuleStyleModel}
     * @private
     */
    _createModuleStyleRecord(tmpRecord) {
      const id = tmpRecord.get('id');
      const json = tmpRecord.toJSON();
      tmpRecord.unloadRecord();
      const record = this.push({
        data: {
          id,
          type: 'module-style',
          attributes: json
        }
      });
      record.set('isCanBeUnload', true);
      record.set('styleMap', (0, _emberCopy.copy)(tmpRecord.get('styleMap'), true));
      record.set('globalVariablesModel', tmpRecord.get('globalVariablesModel'));
      return record;
    }

  });

  _exports.default = _default;
});